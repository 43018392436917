import Vue from 'vue'
import Vuex from 'vuex'
//import {API_URL} from '@/js/constantvars'
//import axios from 'axios'
//import fs from 'fs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showBooking: false,
    showRegister: false,
  },

  mutations: {
    SET_SHOWBOOKING(state,payload) {
      state.showBooking = payload
        // eslint-disable-next-line
        console.log('setShowBooking state: '+state.showBooking)
    },
    SET_SHOWREGISTER(state,payload) {
      state.showRegister = payload
        // eslint-disable-next-line
        console.log('setShowRegister state: '+state.showRegister)
    },
  },

  actions: {
    setShowBooking(context,payload) {
        // eslint-disable-next-line
        console.log('setShowBooking')
      context.commit('SET_SHOWBOOKING', payload)
    },
    setShowRegister(context,payload) {
      // eslint-disable-next-line
      console.log('setShowRegister')
    context.commit('SET_SHOWREGISTER', payload)
  },

  },

  getters: {
    getBookingState (state) {
      return state.showBooking
    },
    getRegisterState (state) {
      return state.showRegister
    },
  }

})
