<template>
  <div>
    <div>
      <v-dialog
      v-model="snackbar"
      width="450"
    >
      <v-card :color="msgcolor">
        <v-container>
          <v-row>
            <v-col>
              <v-row align="center" justify="center">
                <v-icon size="60" class="pb-10 pt-5 white--text">
                  {{ msgicon }}
                </v-icon>
              </v-row>
              <p class="font-weight-light white--text" v-html="msgdlg">
               
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn block :class="msgclass" @click="snackbar=false">Close</v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" id="middleStyle">
          <v-card height="100%" tile>
            <div class="red darken-4 white--text">
              <v-container fluid>
                <v-row align="center" justify="center">
                  <v-col cols="auto">
                    <v-img
                      :src="require('../assets/Logo.png?lazy')"
                      width="50"
                      contain
                    ></v-img>
                  </v-col>
                  <v-col cols="grow">
                    <p class="ma-0 headline">
                      Light of Glory Transport Services Inc.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <v-container fluid style="height: 100%;">
              <v-row justify="center" align="center">
                <v-col cols="12" class="pt-0 pl-0 pr-0">
                  <v-card class="mx-auto text-center" flat>
                    <v-tabs height="50" color="white" active-class="red--text text--darken-3" v-model="tab" grow>
                      <v-tab class="text-none">
                        Request Now
                      </v-tab>
                      <v-divider vertical class="white--text"></v-divider>
                      <v-tab class="text-none">
                        Reservation
                      </v-tab>

                      <!-- REQUEST -->
                      <v-tab-item>
                        <v-card flat id="trans">
                          <v-card-text class="pa-0">
                            <v-form ref="reqform" v-model="valid" :lazy-validation="lazy">
                              <v-container fluid>
                                <v-row>
                                  <v-col cols="auto" class="pt-0 pb-0">
                                    <v-checkbox label="With Access Code" background-color="yellow lighten-3" v-model="acode"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" class="pt-0 pb-2">
                                      <p class="ma-0 text-center">Available in iloilo City and Province only</p>
                                    </v-col>
                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                      <v-text-field
                                        label="Access Code"
                                        dense
                                        outlined
                                        v-model="rcode"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                      <v-text-field
                                        label="Last Name"
                                        dense
                                        outlined
                                        v-model="rlname"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="First Name"
                                        dense
                                        outlined
                                        v-model="rfname"
                                        :rules="reqRules"
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="Last Name"
                                        dense
                                        outlined
                                        v-model="rlname"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="Mobile/Tel. No."
                                        dense
                                        outlined
                                        v-model="rno"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="Current Location(Optional)"
                                        dense
                                        outlined
                                        v-model="rloc"
                                      ></v-text-field>
                                    </v-col>
                                    
                                    <v-col cols="12" class="pt-0 pb-4">
                                        <p class="ma-0 text-center">Request now will call you in one (1) minute</p> 
                                    </v-col>

                                    <!-- <v-col cols="12" class="pt-0 pb-4">
                                      <p class="ma-0 text-center">Optional</p> 
                                    </v-col>

                                    <v-col cols="6" class="pb-0 pt-0">
                                      <v-combobox
                                        label="Preferred Unit"
                                        :items="tunit"
                                        dense
                                        outlined
                                        v-model="rpunit"
                                      ></v-combobox>
                                    </v-col>

                                    <v-col cols="6" class="pb-0 pt-0">
                                      <v-text-field
                                        label="Driver"
                                        dense
                                        outlined
                                        v-model="rdriver"
                                      ></v-text-field>
                                    </v-col> -->

                                    <v-col class="pa-0">
                                      <p class="ma-0 error--text text-center title" v-if="rerr">{{ rerr }}</p>
                                      <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-form>
                            </v-card-text>

                            <v-card-actions>
                              <v-btn rounded class="text-none white--text" block color="red darken-3" :disabled="mobook" @click="requestNow" id="mbook">Send Request</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                          <!-- END of REQUEST tab item-->


                          <!-- RESERVATION -->
                        <v-tab-item>
                          <v-card flat id="trans">
                            <v-card-text class="pa-0">
                              <v-form ref="bookform" v-model="valid" :lazy-validation="lazy">
                                <v-container fluid>
                                  <v-row>
                                    <v-col cols="auto" class="pt-0 pb-0">
                                      <v-checkbox label="With Access Code" background-color="yellow lighten-3" v-model="acode"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" class="pt-0 pb-2">
                                      <p class="ma-0 text-center">Available in iloilo City and Province only</p>
                                    </v-col>
                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                      <v-text-field
                                        label="Access Code"
                                        dense
                                        outlined
                                        v-model="bcode"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                        <v-text-field
                                          label="Last Name"
                                          dense
                                          outlined
                                          v-model="blname"
                                          :rules="reqRules"
                                          required
                                        ></v-text-field>
                                      </v-col>

                                    <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="First Name"
                                        dense
                                        outlined
                                        v-model="bfname"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="Last Name"
                                        dense
                                        outlined
                                        v-model="blname"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0">
                                      <v-menu
                                        ref="pdate"
                                        v-model="pmenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            v-model="pdateFormatted"
                                            label="Pickup Date"
                                            outlined
                                            dense
                                            required
                                            :rules="reqRules"
                                            v-bind="attrs"
                                            @blur="date = parseDate(pdateFormatted)"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="pdate"
                                          no-title
                                          @input="pmenu = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="Pickup Location (Optional)"
                                        dense
                                        outlined
                                        v-model="bloc"
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0">
                                      <v-text-field
                                        label="Pickup Time"
                                        dense
                                        outlined
                                        v-model="btime"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                      <v-text-field
                                        label="Mobile/Tel. No."
                                        dense
                                        outlined
                                        v-model="bno"
                                        :rules="reqRules"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="pt-0 pb-4">
                                      <p class="ma-0 text-center">Book now will call you in one (1) minute</p> 
                                    </v-col>

                                    <!-- <v-col cols="6" class="pb-0 pt-0">
                                      <v-combobox
                                        label="Preferred Unit"
                                        :items="tunit"
                                        dense
                                        outlined
                                        v-model="bpunit"
                                      ></v-combobox>
                                    </v-col>

                                    <v-col cols="6" class="pb-0 pt-0">
                                      <v-text-field
                                        label="Driver"
                                        dense
                                        outlined
                                        v-model="bdriver"
                                      ></v-text-field>
                                    </v-col>
      -->
                                  <v-col class="pa-0">
                                    <p class="ma-0 error--text text-center  title" v-if="rerr">{{ rerr }}</p>
                                    <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                                  </v-col>    
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn rounded class="text-none white--text" size="50" block color="red darken-3" :disabled="mobook" @click="reserveNow" id="mbook">Send Reservation</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-tab-item>
                      <!-- end of RESERVATION tab item -->
                    </v-tabs>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
#middleStyle {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  vertical-align: center;
  left: 0;
}
</style>

<script>
  import axios from 'axios'
  import {API_URL} from '@/js/constantvars'
  //import Register from '../components/Register'
  // import VueRecaptcha from 'vue-recaptcha';
  import {db} from '@/firebase/init'
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  export default {
    data () {
      return {
        titlelist: [],
        safetyimglist: [],
        imageslist: [],
        landlinelist: [],
        globenolist: [],
        smartnolist: [],
        safetylist: [],
        solutionlist: [],
        vibernolist: [],
        feedbackDialog: false,
        tmpcdata: {
          docid: '',
          title: '',
          line1: '',
          line2: ''
        },
        tmpadata: {
          docid: '',
          desc: '',
          title: ''
        },
        tmpbdata: {
          docid: '',
          desc: '',
          title: ''
        },
        pdate: new Date().toISOString().substr(0, 10),
        pmenu: false,
        soon: false,
        moption: false,
        revmobile: '',
        revremarks: '',
        fullscreen: false,
        result: '',
        error: '',
        qreader: 'checking',
        driver: 'Winter Lemon Pie',
        rate: 1,
        rating: false,
        mreg: false,
        mfeed: false,
        bottomNav: 'home',
        msgdlg: '',
        msgcolor: '',
        msgicon: '',
        msgclass: '',
        sheet: false,
        test: false,
        unqctr: null,
        lastname: null,
        firstname: null,
        address: null,
        landline1: null,
        landline2: null,
        landline3: null,
        mobile1: null,
        mobile2: null,
        mobile3: null,
        email: null,
        facebook: null,
        password: null,
        show1: false,
        disable: false,
        registernotif: false,
        register: false,
        lazy: false,
        mobook: false,
        ttime: 20000,
        facezcode: '',
        flname: '',
        ffname: '',
        fcontact: '',
        fremarks: '',
        fplateno: '',
        femail: '',
        feedback: false,
        snackbar: false,
        type: 'element',
        element: '#contact',
        easing: 'easeInOutCubic',
        easings: Object.keys(easings),
        duration: 300,
        offset: 0,
        mtype: 'melement',
        melement: '#mbook',
        measing: 'easeInOutCubic',
        measings: Object.keys(easings),
        mduration: 300,
        moffset: 0,
        ftype: 'felement',
        felement: '#feedback',
        feasing: 'easeInOutCubic',
        feasings: Object.keys(easings),
        fduration: 300,
        foffset: 0,
        bcard: false,
        acode: false,
        berr: null,
        rerr: null,
        btndisabled : true,
        responsive1: false,
        responsive2: false,
        rdriver: '',
        rpunit: '',
        rcode: '',
        rfname: '',
        rlname: '',
        rloc: '',
        rno: '',
        bdriver: '',
        bpunit: '',
        bcode: '',
        bfname: '',
        blname: '',
        bpass: '',
        bloc: '',
        btime: '',
        bno: '',
        cycle: true,
        copt: 'play',
        carou: [],
        testpic: require('../assets/a1.jpg?lazy'),
        date: new Date().toISOString().substr(0, 10),
        mdate: false,
        book: null,
        tab: null,
        valid: true,
        reqRules: [
          v => !!v || 'This field is required',
        ],
        tunit: [
          'Hyundai',
          'Kia',
          'Toyota'
        ],
        icons: [
          {
            icon: 'fab fa-facebook',
            to: 'https://www.facebook.com/lightofglorytaxi/'
          }
      ],
      sheets: [
        {name: 'Facebook', subtitle: 'Follow us on Facebook', icon: 'https://image.flaticon.com/icons/svg/733/733547.svg', r: 'https://www.facebook.com/lightofglorytaxi/'},
      ],
      cards: [
        {
          src: require('../assets/s1.jpg?lazy'),
          text: ''
        },
        {
          src: require('../assets/s2.jpg?lazy'),
          text: ''
        }
      ],
      // mitems: [
      //     {
      //       pic: require('../assets/m1.png'),
      //     },
      //     {
      //       pic: require('../assets/m2.png'),
      //     },
      //     {
      //       pic: require('../assets/m3.png'),
      //     },
      //     {
      //       pic: require('../assets/m4.png'),
      //     },
      //     {
      //       pic: require('../assets/m5.png'),
      //     },
      //     {
      //       pic: require('../assets/m6.png'),
      //     },
      //   ],
      }
    },
    computed: {
      pdateFormatted () {
        return this.formatDate(this.pdate)
      },

      computedDateFormatted () {
        return this.formatDate(this.date)
      },
      target () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.type]
      },
      options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        }
      },
      mtarget () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.mtype]
      },
      moptions () {
        return {
          duration: this.mduration,
          offset: this.moffset,
          easing: this.measing,
        }
      },
      ftarget () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.ftype]
      },
      foptions () {
        return {
          duration: this.fduration,
          offset: this.foffset,
          easing: this.feasing,
        }
      },
    },

    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },

      titlelist () {
        if (this.titlelist.length != 0) {
          this.tmpcdata = {
            docid: this.titlelist[0].docid,
            title: this.titlelist[0].title,
            line1: this.titlelist[0].line1,
            line2: this.titlelist[0].line2,
          }
        }
      },

      solutionlist () {
        if (this.solutionlist.length != 0) {
          this.tmpbdata = {
            docid: this.solutionlist[0].docid,
            title: this.solutionlist[0].title,
            desc: this.solutionlist[0].desc
          }
        }
      },

      safetylist () {
        if (this.safetylist.length != 0) {
          this.tmpadata = {
            docid: this.safetylist[0].docid,
            title: this.safetylist[0].title,
            desc: this.safetylist[0].desc
          }
        }
      },
    },

    mounted () {
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
      },
      beforeDestroy () {
        window.removeEventListener('resize', this.onResponsiveInverted)
      },  

    methods: {
      submitrev () {
          if(this.rate){
            this.rating = false
            this.qreader = 'checking'
      //DriverRateModel(String unqctr, String tdate, String callsign, String contact, double rate) {
            let oRecord = [{unqctr:'', tdate:'', callsign: this.result, 
            contact: this.revmobile, rate: this.rate, remarks: this.revremarks}]
            axios.post(API_URL+'/driverrate/',oRecord).then(response => {
                if(response.data.length>0){
                    //this.rerr = response.data[0].errmsg
                    //this.berr = null
                    this.showErrMsg(response.data[0].errmsg)
                }else{
                    this.initReview()
                    this.showSuccessMsg('Thank You for rating '+this.result)
                    //this.snackbar = true
                }
                // eslint-disable-next-line
                console.log(response.data)
            }).catch(error => {
                this.showErrMsg(error)
                 // eslint-disable-next-line
                 //console.log(error)
            })
          }    
      },
      initReview(){
        this.rate = 1,
        this.revremarks = ''
      },
      onDecode (result) {
      this.result = result
      this.qreader = 'done'
      },
      async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
      },
      btnClicked () {
            this.btndisabled = false
      },

      cpause () {
        this.cycle = false,
        this.copt = 'pause'
      },

      cplay () {
        this.cycle = true,
        this.copt = 'play'
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        this.dmenu = false
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      onResponsiveInverted () {
        if (window.innerWidth < 960) {
          this.responsive1 = false
          this.responsive2 = true
        } else {
          this.responsive1 = true
          this.responsive2 = false
        }
      },
       requestNow(){
         // eslint-disable-next-line
            //console.log('Hello 1')
            let cMsg = ''
            let isProceed = true
            if(this.acode){
               console.log('Hello 1 requestNow')
               if(!this.rcode || !this.rlname){
                  isProceed = false
                  cMsg = 'Incomplete entry'
               }
            }else if(!(this.rfname && this.rlname && this.rno)){
               isProceed = false
               cMsg = `<strong>Incomplete Entry.</strong><br/><br/>
               Required information<strong>:</strong><br/>
               lastname, firstname, contact, location`
            }
           
            if(isProceed){
              // eslint-disable-next-line
                 //console.log('unit: '+this.rpunit) 
                 //console.log('driver: '+this.rdriver)
              this.btndisabled = true
              this.rerr = null
              console.log('requestNow Home.vue')
              //this.rlname  
              const iUniq = Date.now()
              let oRecord = 
                { acezcode: this.rcode ? parseInt(this.rcode) : 0, firstname: this.rfname, lastname: this.rlname,  
                  address: this.rloc, mobile1: this.rno, unit: this.rpunit ? this.rpunit : '',
                  driver: this.rdriver ? this.rdriver : '', btime: '', bdate: '', booking: false,
                  datasync: false, response: '', unqctr: iUniq
                }
              let ref = db.collection('taxibooking').where("unqctr","==",iUniq)  
              ref.onSnapshot(snapshot => {
                  snapshot.docChanges().forEach(change => {
                    let doc = change.doc.data()
                    let docid = change.doc.id
                    doc.docid = docid
                    console.log('Before Modified')
                    if(change.type == 'modified'){
                      console.log('After Modified')
                      if(doc.response){
                         if(doc.response.length > 1){
                            this.showErrMsg(doc.response)
                         }else{
                            this.initRequest()
                            this.$refs.reqform.resetValidation()
                            this.mobook = true
                            this.bcard = false
                            this.showSuccessMsg('Thank You for choosing light of glory taxi. Our operator will get back as soon as possible...')
                         }
                      }
                    }
                  })
              })
              
              //axios.post(API_URL+'/requestnow/',oRecord).then(response => {
              db.collection("taxibooking").add(oRecord).then(response => {  
                  //console.log(response.id)
                  this.btndisabled = false
                  //if(response.data.length>0){
                  if(!response){
                     this.berr = null
                  }
                  // eslint-disable-next-line
                  //console.log(response.data)
              }).catch(() => {
                  this.dialog = false
                  this.btndisabled = false
                  this.showErrMsg("Network Problem")
                  // eslint-disable-next-line
                  //console.log(error)
              })
              setTimeout(() => this.mobook = false, 10000)
              setTimeout(() => this.snackbar = false, 30000)
            }else{
              //this.rerr = 'Incomplete Entry'
              this.berr = null
              this.showErrMsg(cMsg)
            }
       },
       reserveNow(){
            //console.log('Reserve 0')
            let cMsg = ''
            let isProceed = true
            if(this.acode){
               //console.log('Reserve 1')
               if(!this.bcode || !this.blname){
                  //console.log('Reserve 2')
                  isProceed = false
                  cMsg = 'Incomplete entry'
               }
            }else if(!(this.bfname && this.blname && this.bno)){
               isProceed = false
               cMsg = `<strong>Incomplete Entry.</strong><br/><br/>
               Required information<strong>:</strong><br/>
               lastname, firstname, contact, location`
            }
            if(isProceed){
               //console.log('unit: '+this.bpunit) 
               //console.log('driver: '+this.bdriver)

              console.log('reserveNow Home.vue')
              this.btndisabled = true
              this.berr = null
              //console.log('lat 2: '+this.lat)
              const iUniq = Date.now()
              var oRecord = 
                { acezcode: this.bcode ? parseInt(this.bcode) : 0, firstname: this.bfname, lastname: this.blname,  
                  address: this.bloc, mobile1: this.bno, unit: this.bpunit ? this.bpunit : '',
                  driver: this.bdriver ? this.bdriver : '', btime: this.btime ? this.btime : '', 
                  bdate: this.pdate ? this.pdate : '',booking: true,
                  datasync: false,response: '', unqctr: iUniq
                }
              let ref = db.collection('taxibooking').where("unqctr","==",iUniq)  
              ref.onSnapshot(snapshot => {
                  snapshot.docChanges().forEach(change => {
                    let doc = change.doc.data()
                    let docid = change.doc.id
                    doc.docid = docid

                    if(change.type == 'modified'){
                      if(doc.response){
                         if(doc.response.length > 1){
                            this.showErrMsg(doc.response)
                         }else{
                            this.initReserve()
                            this.$refs.bookform.resetValidation()
                            this.mobook = true
                            this.bcard = false
                            this.showSuccessMsg('Thank You for choosing light of glory taxi. Our operator will get back as soon as possible...')
                         }
                      }
                    }
                  })
              })
              
              //axios.post(API_URL+'/booking/',oRecord).then(response => {
              db.collection("taxibooking").add(oRecord).then(response => {  
                  this.btndisabled = false
                  if(!response){
                     //this.berr = response.data[0].errmsg
                     this.rerr = null
                     //this.showErrMsg(response.data[0].errmsg)
                  }
                  // eslint-disable-next-line
                  //console.log(response.data)
              }).catch(() => {
                  this.dialog = false
                  this.btndisabled = false
                  this.showErrMsg("Network Problem")
                  // eslint-disable-next-line
                  //console.log(error)
              })
              setTimeout(() => this.mobook = false, 10000)
              setTimeout(() => this.snackbar = false, 30000)
            }else{
              //this.berr = 'Incomplete Entry'
              this.rerr = null
              this.showErrMsg(cMsg)
            }
       },

       sendFeedback(){
            //console.log('Hello 0')
            if(this.ffname && this.flname && this.fcontact && this.fremarks){
              this.btndisabled = true
              this.berr = null
              var oRecord = [
                { unqctr: '', acezcode: this.facezcode ? this.facezcode : 0, lastname: this.flname, 
                  firstname: this.ffname, contact: this.fcontact, plateno: this.fplateno, 
                  remarks: this.fremarks, email: this.femail, tdate: ''
                }
              ]
              axios.post(API_URL+'/feedback/',oRecord).then(response => {
                  this.btndisabled = false
                  if(response.data.length>0){
                     //this.berr = response.data[0].errmsg
                     this.rerr = null
                     //this.showErrMsg(response.data[0].errmsg)
                     this.showErrMsg('Problem encountered processing your feedback')
                  }else{
                     this.initFeedback()
                     this.bcard = false
                     this.feedback = false
                     this.showSuccessMsg('Thank You for choosing light of glory taxi. Our operator will get back as soon as possible...')
                     //this.snackbar = true
                  }
                  // eslint-disable-next-line
                  //console.log(response.data)
              }).catch(error => {
                  this.dialog = false
                  this.btndisabled = false
                  this.showErrMsg(error)
                  // eslint-disable-next-line
                  //console.log(error)
              })
            }else{
                this.showErrMsg(`<strong>Incomplete Entry.</strong><br/><br/>
                Minimum required information<strong>:</strong><br/>
                lastname, firstname, contact, feedback`)
            }
       },

       initRequest() {
          this.rcode = '',
          this.rlname = '',
          this.rfname = '',
          this.rloc = '',
          this.rno = '',
          this.rerr = '',
          this.rpunit = '',
          this.rdriver = ''
          this.acode = false
       },
       initReserve() {
          this.bcode = '',
          this.blname = '',
          this.bfname = '',
          this.bloc = '',
          this.bno = '',
          this.berr = '' 
          this.bpunit = '',
          this.bdriver = '',
          this.btime = ''
          this.acode = false
       },
       initFeedback() {
          this.facezcode = '',
          this.flname = '',
          this.ffname = '',
          this.fcontact = '',
          this.fremarks = '',
          this.femail = ''
       },
        showRegister() {
            this.register = true
        },
        saveInfo() {
            if(this.lastname && this.firstname && this.mobile1 && this.password){
              if(!this.saveclick){
                  this.saveclick = true
                  //console.log('lat 2: '+this.lat)
                  //console.log('Address 2: '+this.address)
                  var oRecord = [
                    { unqctr: null, lastname: this.lastname, firstname: this.firstname, address:this.address,
                      landline1: this.landline1, landline2: this.landline2, landline3: this.landline3,
                      mobile1: this.mobile1, mobile2: this.mobile2, mobile3: this.mobile3, 
                      email: this.email, facebook: this.facebook, password: this.password          
                    }
                  ]
                  axios.post(API_URL+'/register/',oRecord).then(response => {
                      this.register = false
                      this.saveclick = false
                      this.initValues()
                      this.showSuccessMsg('Thank you for your registration. Our team will get back within 24 hours to give you access code')
                      // eslint-disable-next-line
                      console.log(response.data)
                  }).catch(error => {
                      this.showErrMsg('Problem encountered processing your registration')
                      
                      this.register = false
                      this.saveclick = false
                      // eslint-disable-next-line
                      console.log(error)
                  })
              }
            }else{
                this.showErrMsg(`<strong>Incomplete Entry.</strong><br/><br/>
                Minimum required information<strong>:</strong><br/>
                lastname, firstname, mobile, password`)
            }  
        },
        initValues() {
            // unqctr: null, lastname: this.lastname, firstname: this.firstname, address:this.address,
            // landline1: this.landline1, landline2: this.landline2, landline3: this.landline3,
            // mobile1: this.mobile1, mobile2: this.mobile2, mobile3: this.mobile3, 
            // email: this.email, facebook: this.facebook, password: this.password          
           this.unqctr = null,
           this.lastname = null,
           this.firstname = null,
           this.address = null,
           this.landline1 = null,
           this.landline2 = null,
           this.landline3 = null,
           this.mobile1 = null,
           this.mobile2 = null,
           this.mobile3 = null,
           this.email = null,
           this.facebook = null,
           this.password = null,
           this.rerr = null
        },
        showErrMsg(cMsg) {
           this.msgicon = 'fas fa-exclamation-triangle'
           this.msgcolor = 'error'
           this.msgclass = 'error--text'
           this.msgdlg = cMsg   //.split('\n')
           this.snackbar = true
           setTimeout(() => this.snackbar = false, 30000)
        },
        showSuccessMsg(cMsg){
           this.msgicon = 'far fa-check-circle'
           this.msgcolor = 'success'
           this.msgclass = 'success--text'
           this.msgdlg = cMsg
           this.disable = true
           this.snackbar = true
           setTimeout(() => this.snackbar = false, 30000)
           setTimeout(() => this.disable = false, 10000)
           //this.registernotif = true
           //setTimeout(() => this.registernotif = false, 30000)
    }
  },

  created () {
    let ref = db.collection('imagesdb')
      ref.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.imageslist.length; i++) {
                      if(this.imageslist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.imageslist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.imageslist.length; i++) {
                      if(this.imageslist[i].docid == docid){
                          this.imageslist[i].imglink = doc.imglink
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.imageslist.length; i++) {
                      if(this.imageslist[i].docid == docid){
                          this.imageslist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref1 = db.collection('safetyimgdb')
      ref1.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.safetyimglist.length; i++) {
                      if(this.safetyimglist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.safetyimglist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.safetyimglist.length; i++) {
                      if(this.safetyimglist[i].docid == docid){
                          this.safetyimglist[i].imglink = doc.imglink
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.safetyimglist.length; i++) {
                      if(this.safetyimglist[i].docid == docid){
                          this.safetyimglist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref2 = db.collection('landlinedb')
      ref2.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.landlinelist.length; i++) {
                      if(this.landlinelist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.landlinelist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.landlinelist.length; i++) {
                      if(this.landlinelist[i].docid == docid){
                          this.landlinelist[i].landline = doc.landline
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.landlinelist.length; i++) {
                      if(this.landlinelist[i].docid == docid){
                          this.landlinelist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref3 = db.collection('smartdb')
      ref3.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.smartnolist.length; i++) {
                      if(this.smartnolist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.smartnolist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.smartnolist.length; i++) {
                      if(this.smartnolist[i].docid == docid){
                          this.smartnolist[i].smart = doc.smart
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.smartnolist.length; i++) {
                      if(this.smartnolist[i].docid == docid){
                          this.smartnolist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref4 = db.collection('globedb')
      ref4.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.globenolist.length; i++) {
                      if(this.globenolist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.globenolist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.globenolist.length; i++) {
                      if(this.globenolist[i].docid == docid){
                          this.globenolist[i].globe = doc.globe
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.globenolist.length; i++) {
                      if(this.globenolist[i].docid == docid){
                          this.globenolist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref5 = db.collection('solutiondb')
      ref5.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.solutionlist.length; i++) {
                      if(this.solutionlist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.solutionlist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.solutionlist.length; i++) {
                      if(this.solutionlist[i].docid == docid){
                          this.solutionlist[i].title = doc.title
                          this.solutionlist[i].desc = doc.desc
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.solutionlist.length; i++) {
                      if(this.solutionlist[i].docid == docid){
                          this.solutionlist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref6 = db.collection('titledb')
      ref6.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.titlelist.length; i++) {
                      if(this.titlelist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.titlelist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.titlelist.length; i++) {
                      if(this.titlelist[i].docid == docid){
                          this.titlelist[i].title = doc.title
                          this.titlelist[i].line1 = doc.line1
                          this.titlelist[i].line2 = doc.line2
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.titlelist.length; i++) {
                      if(this.titlelist[i].docid == docid){
                          this.titlelist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref7 = db.collection('safetydb')
      ref7.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.safetylist.length; i++) {
                      if(this.safetylist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.safetylist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.safetylist.length; i++) {
                      if(this.safetylist[i].docid == docid){
                          this.safetylist[i].title = doc.title
                          this.safetylist[i].desc = doc.desc
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.safetylist.length; i++) {
                      if(this.safetylist[i].docid == docid){
                          this.safetylist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref8 = db.collection('titledb')
      ref8.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.titlelist.length; i++) {
                      if(this.titlelist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.titlelist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.titlelist.length; i++) {
                      if(this.titlelist[i].docid == docid){
                          this.titlelist[i].title = doc.title
                          this.titlelist[i].desc = doc.desc
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.titlelist.length; i++) {
                      if(this.titlelist[i].docid == docid){
                          this.titlelist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })

      let ref9 = db.collection('viberdb')
      ref9.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              let doc = change.doc.data()
              let docid = change.doc.id
              doc.docid = docid
              if(change.type == 'added') {
                  let isExist = false
                  for(let i=0; i<this.vibernolist.length; i++) {
                      if(this.vibernolist[i].docid == docid) {
                          isExist = true
                          break
                      }
                  }if (!isExist) {
                    this.vibernolist.push(doc)
                  }
              }else if(change.type == 'modified') {
                  for(let i=0; i<this.vibernolist.length; i++) {
                      if(this.vibernolist[i].docid == docid){
                          this.vibernolist[i].title = doc.title
                          this.vibernolist[i].desc = doc.desc
                          break
                      }
                  }
              }else if(change.type == 'removed') {
                  for(let i=0; i<this.vibernolist.length; i++) {
                      if(this.vibernolist[i].docid == docid){
                          this.vibernolist.splice(i,1)
                          break
                      }
                  }
              }
          })
      })
  },
}
</script>   