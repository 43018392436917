import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBfBCyGIlW-MFfSwGJqTOnOhmQq1GYjMPI",
    authDomain: "logdb-573f1.firebaseapp.com",
    databaseURL: "https://logdb-573f1.firebaseio.com",
    projectId: "logdb-573f1",
    storageBucket: "logdb-573f1.appspot.com",
    messagingSenderId: "522965047966",
    appId: "1:522965047966:web:9455ca46c777935478f98f"
}  

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

//firebase db
export const db = firebase.firestore()

