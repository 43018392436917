<template>
  <div>
    <v-app-bar
      color="#272727"
      dark
      app
      height="0px"
      flat
      v-if="responsive1"
    >
    <!-- <v-app-bar
      color="white"
      dark
      app
      height="60px"
      flat
    > -->
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
    </v-app-bar>

    <v-card
      width="100%"
      tile
      flat
      id="footerDiv"
    >
      <p class="ma-0 text-center font-weight-light caption">
        Ver 1.2
      </p>
    </v-card>
    <!-- <v-card color="#272727" flat tile v-if="responsive2">
        <v-row justify="center" align="center">
          <p class="mt-5 headline text-center amber--text text--lighten-1">Light of Glory Taxi Services
          <br>
          <span class="body-2 font-weight-light white--text">Available in Iloilo City and Province only</span>
          </p>
        </v-row>
    </v-card> -->
    <!-- <v-navigation-drawer    
      v-model="drawer"
      app
      persistent
      floating
      class="tertiary"
      disable-resize-watcher
      width="300px"
    >
      <v-img src='https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg' height="100%" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)">

        <v-layout align-center>
          <v-flex class="mt-2 mb-2 ml-3">
            
          </v-flex>
        </v-layout>

        <v-divider class="mr-3 ml-3 white"></v-divider>

        <v-list>
          <v-list-item       
            v-for="item in items"
            :key="item.title"
            :to="item.r"
            active-class="green lighten-2"
            id="v_list2"
            link
          >
            <v-list-item-icon id="navicon">
                <v-icon class="white--text">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text font-weight-light">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-img>
    </v-navigation-drawer> -->
  </div>
</template>

<script>
//import Register from './Register.vue'
  export default {
    //props: ['box'],
    //components: { Register },
    data () {
      return {
        titlelist: [],
        responsive1: false,
        responsive2: false,
        drawer: false,
        items: [
          { title: 'Dashboard', icon: 'dashboard',r: '/' },
          { title: 'test', icon: 'dashboard',r: '/test' },  
        ],
        right: null,
      }
    },
    mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResponsiveInverted)
    },

    // computed: {
    //   open: function() {
    //   return this.box;
    //   },
    // },

    methods: {
      btnBookClicked () {
        // eslint-disable-next-line
        console.log('Clicked')
        //this.$emit('update:box', this.box = true)
        //this.box = !this.box;
        this.$store.dispatch('setShowBooking', true)
      },

      onResponsiveInverted () {
      if (window.innerWidth < 960) {
        this.responsive1 = false
        this.responsive2 = true
      } else {
        this.responsive1 = true
        this.responsive2 = false
        }
      }
    },
  }
</script>