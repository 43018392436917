import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueQrcodeReader);
//import * as VueGoogleMaps from 'vue2-google-maps'
//import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

/*Vue.use(VueGoogleMaps, {
  load: {
    key: '6LdABcMUAAAAANpwwrh_pH1Bnx_vYOoTID4YwCtq',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  installComponents: true
})*/

